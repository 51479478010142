import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, withPrefix } from "gatsby"
import "./categoriasnoticias.css"
import Header from "../../../components/header"
import Footer from "../../../components/footer"
import Seo from "../../../components/seo"
import BotonFlotante from "../../../components/botonflotante"
import Categorianoticia from "../../../components/categorianoticia"
import Accesibilidad from "../../../components/accesibilidad"
import Helmet from "react-helmet"

const CategoriaNoticia = props => {
  ;<Helmet>
    <script
      src={withPrefix("js/code.jquery.com_jquery-1.12.4.min.js")}
    ></script>
    <script src={withPrefix("js/paginador.js")}></script>
  </Helmet>

  let categoria = props.location.search.slice(1)

  categoria = decodeURI(categoria)

  const query = useStaticQuery(graphql`
    query CategoriaNoticias_a {
      allStrapiNoticias {
        edges {
          node {
            Contenido
            Imagen {
              url
            }
            Palabra_clave
            Categoria
            Titulo
            id
            Slug
            Site
          }
        }
      }
    }
  `)
  const projects = query.allStrapiNoticias.edges
  let url_global = process.env.GATSBY_API_URL
  let image_url =
    "url(" + url_global + "/uploads/" + "valla_03_6e3f99d9a6.jpg" + ")"

  let currentSite = null

  if (typeof window !== "undefined") {
    currentSite = window.localStorage.getItem("currentSite")
  }

  return (
    <>
      <Accesibilidad />
      <Header site="personas" />

      <Seo />
      <BotonFlotante />
      <div
        className="absolute fondo_central_image_detalle w-screen ancho_banner_detalleblog altura_banner lg:w-screen altura_banner posicion_lado_banner_ventaja -top-0 bg-cover bg-right"
        title="valla noticias"
        style={{
          backgroundImage: `${image_url}`,
        }}
      ></div>
      <div
        className="container mx-auto categoria_blog text-poppins-bold lg:relative titulo_ventaja_lg"
        style={{
          color: "#256238",
          // paddingLeft: "10rem",
          top: "7rem",
        }}
      >
        NOTICIAS
      </div>

      <br></br>
      <br></br>
      <div className="mitexto">
        <div
          style={{ marginTop: "12rem" }}
          className="vertical_contenido_blogs"
        >
          <p className="ml-64 container mitexto mitexto_color title text-poppins-bold green-primary pt-28 alinear_texto_resultado">
            RESULTADOS DE BUSQUEDA
          </p>
          <br></br>
          <br></br>
          <div
            className="mitexto relative alinear_movil_blog_resultado mx-auto container justify-items-center lg:grid grid-cols-3 gap-4"
            style={{
              gap: "4rem !important",
              marginTop: "0rem",
            }}
          >
            <div className="mitexto mx-auto col-span-2">
              <div className="mitexto block centrado_vertical flex flex-wrap lg:inline-grid gap-4 grid-cols-2">
                {/* PARA VERSION MOVIL */}
                <div className="hidden mx-auto alinear_categoria_x categoria_oculta">
                  <Categorianoticia />
                </div>

                {/* PARA VERSION MOVIL */}

                {projects.map(({ node: project }) => {
                  if (
                    project.Site === currentSite &&
                    (project.Palabra_clave === categoria ||
                      project.Categoria === categoria)
                  ) {
                    return (
                      <div className="mitexto mx-auto square transform hover:-translate-y-1 hover:scale-110 transition duration-500">
                        <a
                          href={"/noticias/detalle/?" + project.Slug}
                          target="_self"
                        >
                          <div className="mitexto square">
                            <img
                              className="img_dos mask"
                              src={url_global + project.Imagen[0].url}
                              alt={project.Titulo}
                              title={project.Titulo}
                            />
                            <div className="h1 mitexto mitexto_color text-poppins-bold color-texto-parrafo">
                              {project.Titulo}
                            </div>
                            <div
                              style={{
                                position: "relative",
                                top: "1rem",
                                overflowY: "hidden",
                                overflowX: "hidden",
                                gridArea: "1/1",
                                maxHeight: "102px",
                                maxWidth: "321px",
                                paddingLeft: "26px",
                                textAlign: "justify",
                                marginBottom: "2rem",
                              }}
                              layout="fullWidth"
                              dangerouslySetInnerHTML={{
                                __html: project.Contenido,
                              }}
                            />

                            <p
                              className="green-secundary text-poppins-bold"
                              style={{ paddingLeft: "7%" }}
                            >
                              {project.Palabra_clave}
                            </p>
                          </div>
                        </a>
                      </div>
                    )
                  }
                })}
              </div>
              <div id="pagination-container"></div>
            </div>
            <div className="categoria_oculta_dos alinear_categoria_x">
              <Categorianoticia />
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}

CategoriaNoticia.propTypes = {
  siteTitle: PropTypes.string,
}

CategoriaNoticia.defaultProps = {
  siteTitle: ``,
}

export default CategoriaNoticia
